.root {
  position: relative;
  overflow: hidden;
  background: var(--color-border);
  box-sizing: border-box;
  border-radius: 99999px;
  width: 100%;
  height: 4px;

  /* Fix overflow clipping in Safari */
  /* https://gist.github.com/domske/b66047671c780a238b51c51ffde8d3a0 */
  transform: translateZ(0);
}

.text {
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.indicator {
  width: 100%;
  height: 100%;
  transition: transform 660ms cubic-bezier(0.65, 0, 0.35, 1);
}

.indicator:not(.clipped)::after {
  content: "";
  position: absolute;
  right: -0;
  top: 0;
  bottom: 0;
  width: 2px;
  background: var(--color-background);
}
