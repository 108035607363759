.label {
  display: flex;
  align-items: center;
  column-gap: 6px;
  font: var(--font-text);
  color: var(--color-text-heading);
  font-variant: inherit;
}

.label.bold {
  font: var(--font-text-bold);
  font-variant: inherit;
}

.label.primary {
  color: var(--color-primary);
}

.padded {
  padding: 0 8px;
}

.text {
  text-overflow: ellipsis;
  min-width: 0;
  display: block;
  font: inherit;
  font-variant: inherit;
  flex-grow: 1;
  color: inherit;
}

.label.fitContainer {
  flex-grow: 1;
  max-width: 100%;
}

.subtle {
  color: var(--color-text-secondary);
}

.icon {
  color: var(--color-icon);
  flex: 0 0 auto;
  margin-top: -2px;
}

.iconRight {
  color: var(--color-icon);
  flex: 0 0 auto;
}

.icon.only {
  margin-right: 0;
}

.icon > *,
.iconRight > * {
  color: inherit;
}


.sectionLabel {
  font: var(--font-text-small);
  color: var(--color-text-secondary);
  letter-spacing: 0.2px;
  text-transform: uppercase;
  flex-grow: 0;
}

.sectionLabel .text.medium {
  font: var(--font-text-medium);
  text-transform: none;
  color: var(--color-text);
}

.sectionLabel.bold {
  font: var(--font-text-small-bold);
}

.sectionLabelIcon {
  margin-right: 3px;
}

/*
* Sizes
*/
.text.large {
  font: var(--font-text-large);
  font-variant: inherit;
}

.text.large.bold {
  font: var(--font-text-large-bold);
  font-variant: inherit;
}

.text.medium {
  font: var(--font-text-medium);
  font-variant: inherit;
}

.text.medium.bold {
  font: var(--font-text-medium-bold);
  font-variant: inherit;
}

.text.small {
  font: var(--font-text-small);
  font-variant: inherit;
}

.text.small.bold {
  font: var(--font-text-small-bold);
  font-variant: inherit;
}