/* Resource Item Styles */
.tile {
  display: block;
  width: 100%;
  padding: 16px;
}

.person {
  font: var(--font-text-medium);
}

.pin.pinned {
  color: var(--color-text);
}

.strong {
  color: var(--color-icon-strong);
}

.clip {
  overflow: hidden;
}