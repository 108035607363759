.embed {
  cursor: default;
}

.embed.selected {
  border-radius: var(--border-radius-small);
  outline: 1px solid var(--color-primary);
}


.onHover {
  opacity: 0;
}

.embed.selected .onHover,
.embed:focus-within .onHover,
.embed:hover .onHover {
  opacity: 1;
}

.header {
  cursor: pointer;
}

.link {
  display: inline-block;
  border-radius: var(--border-radius-small);
  font: inherit;
  vertical-align: baseline;
  width: auto;
  max-width: calc(min(100%, 40vw));
}

.link .icon {
  display: inline-block;
  vertical-align: center;
}


.underline,
.embed .text,
.link .text {
  font: inherit;
  text-decoration: underline;
}

.clip {
  max-height: 600px;
  overflow: hidden;
}

.scrollbox {
  overflow: auto;
  position: absolute;
  padding: 30px 12px 4px;
  bottom: 0;
  left: 0;
  right: 0;
  /* Linear gradient that starts from bottom solid white, half way still solid white then fades to transparet at top */
  background: linear-gradient(0deg, var(--color-background) 0%, rgba(var(--color-rgb-background), 0.9) 50%, rgba(var(--color-rgb-background), 0) 100%);
}

/*
* Image Embed
*/

.image {
  display: block;
}

.image img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  max-height: 100%;
}

.image .details {
  padding: 0 6px;
  height: 30px;
}

.typeSelect {
  min-width: 200px;
}

.actionContainer {
  position: relative;
}

.actionButton {
  position: absolute;
  top: 0;
  right: -1px;
  transform: translate(0, round(-50%, 1px));
}

.actionContainer::before {
  content: '';
  display: block;
  position: absolute;
  right: -1px;
  top: 0;
  bottom: 0;
  width: 40px;
  height: 30px;
  transform: translate(0, round(-50%, 1px));
  background: linear-gradient(90deg,rgba(var(--color-rgb-background), 0)  0%, rgba(var(--color-rgb-background), 0.95) 25%);
}