.pane.pane {
  display: flex;
  flex-direction: column;
  column-gap: 0;
  justify-content: stretch;
  max-height: 500px;
}

.paneContainer {
  padding: 10px 20px;
  flex: 1 1 auto;
  overflow: auto;
}

.paneActions {
  padding: 10px 15px;
  padding-top: 0;
}

.noteType {
  margin-left: 25px;
}

.timeline::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 20px;
  width: 1px;
  height: 100%;
  background-color: var(--color-border);
}

.messageBox {
  background: var(--color-background-tertiary);
}