.container {
  overflow: auto;
  display: grid;
  grid-template-columns: 300px 1fr;
  width: 100%;
  max-width: 100%;
  /* New z-index stacking context */
  isolation: isolate;
}


.sidebar {
  position: sticky;
  z-index: 2;
  left: 0;
  background: var(--color-background);
  border-right: var(--border-secondary);
  width: 100%;
  padding: 0px 6px;
}

.sidebar .header {
  height: 60px;
  padding-left: 4px;
  position: sticky;
  top: 0;
  z-index: 1;
  background: var(--color-background);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.clip {
  /* overflow: clip; */
  max-width: 80%;
}

.main {
  position: relative;
}

.timelines {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 60px;
  pointer-events: none;
}

.timelineGroup {
  position: relative;
  display: block;
  width: 100%;
  /* Matches GroupHeader */
  margin-top: 42px; 
  /* Matches AddEntityInput */
  margin-bottom: 34px;
}

.groupItem {
  top: -20px;
  position: absolute;
  display: block;
  height: 1px;
  pointer-events: initial;
}
.groupItem .rule {
  background: var(--color-user-red);
  height: 1px;
}
.groupItem .rule::before,
.groupItem .rule::after {
  content: "";
  position: absolute;
  top: -6px;
  bottom: -6px;
  width: 2px;
  background: var(--color-user-red);
}
.groupItem .rule::after {
  right: -2px;
}
.groupItem .rule::before {
  left: -2px;
}

.onlyGroup {
  margin-top: 0;
}

.markers {
  z-index: 1;
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
}

.marker {
  position: absolute;
  top: 1px;
  bottom: 1px;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  pointer-events: none;
}

.marker::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  background: var(--color-user-red);
  z-index: -1;
}

.marker .label {
  top: 0;
  position: sticky;
  top: 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 6px;
  border-radius: 12px;
  outline: 1px solid var(--color-background);
  font: var(--font-text-small-bold);
  background: var(--color-user-red);
  color: var(--color-text-inverse);
}

.item {
  pointer-events: initial;
  position: absolute;
  user-select: none;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 4px;
  transition: box-shadow 50ms ease-in-out;
  border: var(--border-secondary);
  border-radius: var(--border-radius-small);
  box-shadow: var(--shadow-elevation-low);
  background-color: var(--color-background);
}

.item.selected {
  background: var(--color-subtle-primary-active);
}

.item:hover {
  box-shadow: var(--shadow-elevation-mid);
}

.item:active {
  box-shadow: var(--shadow-elevation-none);
}

.gridContainer {
  flex-direction: row;
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  grid-template-rows: 60px 1fr;
  width: 100%;
  height: 100%
}

.gridContainer .headers {
  position: sticky;
  display: flex;
  z-index: 1;
  top: 0;

  flex-direction: column;
  justify-content: flex-end;
  padding: 6px 0;
  border-bottom: var(--border-secondary);
  background: var(--color-background);
}

.gridContainer .actions {
  position: fixed;
  padding-top: 8px;
  padding-left: 12px;
  padding-right: 4px;
  right: 0px;
  /* Fading background in from left */
  background: linear-gradient(to right, rgba(var(--color-rgb-background), 0) 0%, var(--color-background) 10%);
  z-index: 2;
}

.headers .header {
  display: flex;
}

.headers .header.sticky > * {
  position: sticky;
  left: calc(300px + 4px);
  display: block;
}


.grids {
  z-index: -1;
  display: flex;
  width: 100%;
  height: 100%;
}

.column {
  display: block;
  height: 100%;
  border-right: var(--border-secondary);
  background-color: var(--color-background-secondary);
}


.resizer {
  position: absolute;
  display: block;
  width: 20px;
  top: 1px;
  bottom: 1px;
  cursor: ew-resize;
  z-index: 1;
}

.resizer::after {
  content: "";
  opacity: 0;
  display: block;
  width: 4px;
  height: 100%;
  background: var(--color-text);
  border-radius: 3px;
  transition: transform 0.3s var(--snappy-bezier), opacity 0.3s var(--snappy-bezier);
}

.resizer.left {
  left: -8px;
  padding-right: 8px;
}

.resizer.left::after {
  transform: translateX(5px);
}

.resizer.right {
  right: -8px;
  padding-left: 8px;
  display: flex;
  justify-content: flex-end;
}

.resizer.right::after {
  transform: translateX(-5px);
}

.resizer:hover::after {
  opacity: 1;
  transform: translateX(0);
}




/*
* Hiden Sidebar
*/

.container.sideBarHidden {
  grid-template-columns: 40px 1fr;
}

.sideBarHidden .sidebar {
  padding: 6px 3px;
}

.sideBarHidden .headers .header>* {
  left: calc(40px + 4px);
}

.sideBarHidden .sidebar .header {
  padding-left: 0;
}
