.dialog.fullWidth {
  max-width: 520px;
  width: 100vw;
}

.container {
  align-items: flex-start;
}

.content {
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
}

.fullWidth {
  width: 100%;
}