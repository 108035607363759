
/* Hack because font: var(--font) always overrides this down the chain... */
/* TODO: Refactor label to accept variant */
.label {
  font-variant-numeric: tabular-nums !important;
}

.ampm {
  font-size: 0.7em;
  text-transform: uppercase;
  margin-left: 1px;
}