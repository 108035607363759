.hidden {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.underline {
  text-decoration: underline;
  display: inline;
  cursor: pointer;
}

.progress,
.target {
  min-height: 50vh;
  width: 50vw;
  display: flex;
  align-items: center;
  justify-content: center;

}

.target {
  border: 2px dashed var(--color-border-secondary);
  border-radius: var(--border-radius-medium);
  color: var(--color-text-secondary);
  transition: all 0.3s var(--snappy-bezier);
}

.target .text {
  color: inherit;
  font: var(--font-text-large);
}

.target:global(.file-drop-dragging-over-frame) {
  border-color: var(--color-text);
  background-color: var(--color-background-secondary);
}

.target:global(.file-drop-dragging-over-target) {
  border-color: var(--color-primary);
  background-color: var(--color-subtle-primary-hover);
  margin: 10px;
}