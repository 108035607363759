.variable {
  display: inline-flex;
  align-items: baseline;
  /* color: var(--color-yellow); */
  background: var(--color-user-yellow-5);
  border: 1px solid var(--color-user-yellow-3);
  vertical-align: baseline;
  padding: 0px 3px 2px 3px;
  margin-left: -1px;
  margin-right: -1px;
  margin-bottom: -2px;
  border-radius: 100px;
}

.variable::before {
  content: '{';
  color: var(--color-user-yellow);
  margin-right: 1px;
}
.variable::after {
  content: '}';
  color: var(--color-user-yellow);
  margin-left: 1px;
}

.variable:hover {
  color: var(--color-primary-hover);
}

.divider {
  margin-top: 10px;
  margin-bottom: 10px;
}