/* Resource Item Styles */
.link {
  display: block;
  width: 100%;
  flex: 0 0 auto;
}

.person {
  font: var(--font-text-medium);
}

.pin {
  opacity: 0;
}

.pin.pinned {
  opacity: 1;
  color: var(--color-text);
}

.link:hover .pin {
  opacity: 1;
}

.strong {
  color: var(--color-icon-strong);
}