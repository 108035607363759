
.sheet.sheet {
  padding: 0;
  min-height: 100px;
  max-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-shadow: var(--shadow-elevation-mid);
  transition: box-shadow 100ms ease;
}

.sheet.sheet:hover {
  box-shadow: var(--shadow-elevation-high);
}

.card.card {
  transition: box-shadow 50ms ease-in-out;
  border: var(--border-tertiary);
  border-radius: var(--border-radius-medium);
  box-shadow: var(--shadow-elevation-low);
  background-color: var(--color-background);
  overflow: hidden;
}

.card:hover {
  box-shadow: var(--shadow-elevation-mid);
}

.card:active {
  box-shadow: var(--shadow-elevation-none);
}


.card.full,
.sheet.sheet.full {
  height: 100%;
  width: 100%;
}

.collapsed.collapsed {
  min-height: 0;
}

.onHover {
  opacity: 0;
  transition: opacity 100ms ease;
}

.header {
  padding: 4px 6px 4px 12px;
}

.card:hover .onHover,
.sheet:hover .onHover {
  opacity: 1;
}

.title {
  color: var(--color-text);
}

.addButton {
  padding: 8px 14px;
}

.grouped {
  height: 100%;
}

.list {
  min-height: 200px;
  overflow: auto;
  padding: 0px 6px 4px 12px;
}

.padded {
  padding: 0 8px;
}