.row {
  background: var(--color-background)
}

.row:hover {
  background: var(--color-subtle-primary-hover);
}


.row:hover.selected,
.row.selected,
.row:active {
  background: var(--color-subtle-primary-active);
}