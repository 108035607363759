.rightNav {
  min-height: 100%;
  padding: 10px;
  display: block;
  width: 58px;
}

.sideButton {
  position: relative;
}

.counter {
  position: absolute;
  top: -5px;
  right: -5px;
}

/* Hack: Force inner sheets to have a border */
.slideIn > * > * > * >* > * {
  border: var(--border-tertiary) !important;
}