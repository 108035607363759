.control {
  background-color: var(--color-background-tertiary);
}

.tasksSection {
  min-height: 200px;
}

.modal {
  max-width: 900px;
}

.left, .leftThicc  {
  transition: flex-basis 0.4s var(--snappy-bezier);
}

.left {
  flex-basis: 30%;
}

.leftThicc {
  flex-basis: 50%;
}

.right {
  flex-basis: 100%;
}

.spaceBottom {
  margin-bottom: 20px;
}