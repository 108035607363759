.bar {
  padding: 8px 12px;
  width: 100%;
}

.title {
  font: var(--font-text-large-bold);
  color: var(--color-text-heading);
}

.icon {
  margin-top: -25px;
  z-index: 1;
}

.cover {
  height: 60px;
  margin: 8px;
  width: calc(100% - 16px)
}

/* Pane Styles */
.panel {
  padding: 5px 20px;
}

.heading {
  font: var(--font-heading-bold);
  color: var(--color-text-heading);
}

.secondary {
  color: var(--color-text-secondary);
}

.creator {
  display: inline-flex;
  vertical-align: text-bottom;
  font: inherit;
  color: inherit;
}

.blue {
  color: var(--color-primary);
}

.location {
  margin-left: -8px;
}