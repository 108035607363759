.banner {
  position: relative;
  padding: 2px 6px;
}

.rounded {
  border-radius: 4px;
}

.label,
.label > * {
  color: inherit;
}

.dismiss {
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}