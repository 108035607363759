.preview {
  max-height: 90vh;
}

.scaled {
  width: 130%;
  transform: scale(0.77);
  transform-origin: 0 0;
  margin-bottom: -5%;
  margin-right: -15%;
}