.radioItem {
  all: unset;
  width: 18px;
  height: 18px;
  
  border: 2px solid var(--color-border);
  background: var(--color-invisible);
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.radioItem[data-state="checked"] {
  background: var(--color-primary);
  border-color: var(--color-invisible);
}

.radioIndicator {
  all: unset;
  width: 8px;
  height: 8px;
  background: var(--color-background);
  border-radius: 50%;
  display: block;
}
