.card {
  padding: 7px 6px;
}

.upper {
  min-width: 0;
  margin-bottom: -6px;
}

.middle {
  min-width: 0;
}

.title {
  color: var(--color-text-heading);
  font: var(--font-text);
  padding: 5px 8px;
  margin-left: -6px;
  max-width: 100%;
  min-width: 0;
}

.subtle {
  color: var(--color-text-secondary);
}

.subGroup {
  flex: 0 0 auto;
  padding: 0 4px;
  width: 300px;
  border-top: none;
  padding-top: 0;
  margin-top: 0;
}