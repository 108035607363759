.section {
  position: relative;
  border-radius: var(--border-radius-medium);
}

.secondary {
  background-color: var(--color-background-tertiary);
  padding: 12px;
}

.background {
  background-color: var(--color-background);
}

.noWrap {
  text-wrap: nowrap;
}

.header {
  padding: 0;
}

.header.inset {
  padding: 0 6px;
}