.opSelect {
  display: inline-block;
  width: auto;
}

.opSelectTrigger {
  display: inline-block;

  width: auto;
  color: var(--color-text-primary);
  display: flex;
}

.header {
  background: var(--color-background-tertiary);
  border-bottom: var(--border-secondary);
  padding: 8px;
  margin: -4px;
  margin-bottom: 4px;
  width: calc(100% + 8px);
  border-top-right-radius: inherit;
  border-top-left-radius: inherit;
  display: flex;
  justify-content: space-between;
}

.propTitle {
  padding: 4px;
  font: var(--font-text);
  color: var(--color-text-secondary);
  display: flex;
  column-gap: 4px;
}

.opPopover {
  min-width: 100px;
  width: auto;
}
