.select {
  min-width: 300px;
  max-width: 100%;
}

.buttonContainer {
  width: 100%;
  background: var(--color-background-tertiary);
  border-radius: 6px;
}

.button {
  width: 100%;
  min-width: 0;
}

.manageProps {
  padding: 0 6px;
}