
.dropdown.dropdown {
  max-width: 450px;
}

.control {
  background-color: var(--color-background-tertiary);
}

.body {
  min-height: 250px;
}

.smallInput {
  width: 50px;
}