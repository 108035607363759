.list {
  width: 100%;
  height: 100%;
  flex: 1 1 auto;
}

.addButton {
  padding-top: 8px 0px;
  padding-top: 0;
}

.filters {
  padding-bottom: 20px;
}

.canvas {
  position: relative;
  width: 100%;
  height: 500px;
  border-radius: var(--border-radius-medium);
  overflow: hidden;
}

.canvas:hover {
  background: rgba(var(--color-rgb-background), 0.2);
}

.canvas > * {
  /* Prevents capturing scroll events, but can still touch nodes */
  pointer-events: none;
}