.popover {
  background: var(--color-background);
  box-shadow: var(--shadow-popover);
  border-radius: 4px;
  padding: 2px;
  width: 232px;
  max-height: 50vh;
  overflow: auto;
  z-index: 1;
}

.label {
  font: var(--font-text-small);
  text-transform: uppercase;
  letter-spacing: 0.2px;
  padding: 6px 4px 0;
  color: var(--color-text-tertiary);
}

.divider {
  background-color: var(--color-border-secondary);
  height: 1px;
  margin: 4px 0;
}

.divider:first-child,
.divider:last-child {
  display: none;
}

.divider + .divider {
  display: none;
}

.inherit {
  all: unset;
}
