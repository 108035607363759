.container {
  position: relative;
  width: 100%;
}

.content {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}