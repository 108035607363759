.sheet.sheet {
  min-width: 54px;
  width: 54px;
  padding: 20px 10px;
  position: relative;
  background-position: center;
  background-size: cover;
  overflow: hidden;
}

.sheet.color {
  background: linear-gradient(180deg, #79A9BE 0%, #91D4DF 100%);
}

.sheet.grey {
  color: var(--color-text);
}

.backIcon {
  color: var(--color-background);
}

.sheet.grey .backIcon {
  color: var(--color-text-tertiary);
}

.sheet::before {
  content: "";
  position:absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}

.sheet.image::before {
  background: linear-gradient(0deg, rgba(0, 29, 55, 0), rgba(0, 29, 55, 0.3));
  background-position: center;
  background-size: cover;
}

.sheet.image:hover::before {
  background: linear-gradient(0deg, rgba(0, 29, 55, 0.3), rgba(0, 29, 55, 0.4));
  background-position: center;
  background-size: cover;
}

.sheet.image:active::before {
  background: linear-gradient(0deg, rgba(0, 29, 55, 0.55), rgba(0, 29, 55, 0.55));
  background-position: center;
  background-size: cover;
}

.breadcrumb {
  transform-origin: left;
  transform: translateX(17px) rotate(90deg);
  pointer-events: none;
  margin-top: 10px;
  overflow: visible;
}

.breadcrumbLink.breadcrumbLink {
  font: var(--font-text-x-large-bold);
  color: var(--color-background);
}

.breadcrumbLink:hover {
  font: var(--font-text-x-large-bold);
}

.sheet.grey .breadcrumbLink {
  color: var(--color-text);
}

.breadcrumbItem:not(:last-child) {
  opacity: 0.7;
}

.back {
  display: block;
  border-radius: 100px;
  color: var(--color-text-inverse);
  width: 24px;
  height: 24px;
  transform: translate(-4px, -4px);
  transition: transform 0.2s ease;
}

.back > *,
.back > * > * {
  color: inherit;
}

.projectIcon {
  width: 28px;
  height: 28px;
  line-height: 28px;
  font-size: 28px;
}