.overlay {
  position: fixed;
  inset: 0;
}

.container {
  position: fixed;
  /* Mid transparency */
  background: linear-gradient(180deg, rgba(var(--color-rgb-background), 0.3) 0%, rgba(var(--color-rgb-background), 0.9) 30%);
  backdrop-filter: blur(20px);
  box-shadow: var(--shadow-popover);
  border-radius: 16px;
  color: var(--color-text-secondary);
  transition: all 300ms var(--snappy-bezier);
  max-height: 100vh;
  overflow-y: auto;
}


.container.center {
  bottom: round(55%, 1px);
  top: auto;
  right: round(50%, 1px);
  left: auto;
  transform: translate(round(50%, 1px), round(50%, 1px));
  max-height: 80vh;
}

.container.bottomRight {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.container.entering {
  animation: contentShow 150ms var(--snappy-bezier);
}

.container.entering.bottomRight {
  animation: bounce 500ms var(--snappy-bezier);
}

.container:focus {
  outline: none;
}

.secondary {
  width: 40Vw;
  min-width: 440px;
  max-width: 640px;
}

.grip {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 30px;
  display: flex;
  justify-content: center;
  z-index: 1;
}

.grip.visible::after,
.grip:hover::after {
  opacity: 1;
  transform: translateY(5px);
}

.grip::after {
  content: "";
  display: block;
  opacity: 0;
  width: 80px;
  height: 4px;
  background: var(--color-text);
  border-radius: 3px;
  transition: transform 0.3s var(--snappy-bezier), opacity 0.3s var(--snappy-bezier);
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(round(50%, 1px), 100%) scale(0.2);
    transition: none;
  }

  to {
    opacity: 1;
    transform: translate(round(50%, 1px), round(50%, 1px)) scale(1);
    transition: none;
  }
}

@keyframes bounce {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }

  40% {
    transform: translateY(-50px);
  }

  80% {
    transform: translateY(20px);
    opacity: 1;
  }

  100% {
    transform: translateY(0);
  }
}