.button {
  max-width: 100%;
  width: 100%;
}

.label {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 160px;
}

.label > *:last-child {
  overflow: hidden;
}

.subtle {
  color: var(--color-text-secondary);
  filter: saturate(0.4);
}

.notTooLong > *:last-child {
  max-width: 80px;
  overflow: hidden;
}

.faintButton:hover {
  outline: var(--border-secondary);
  border-radius: var(--border-radius-medium);
}

.slash {
  margin: 0 6px;
}

.breadcrumbButton {
  border-radius: 0;
}