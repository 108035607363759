.control {
  background-color: var(--color-background-tertiary);
}

.menu {
  padding-left: 8px;
  padding-right: 8px;
}

.initial {
  padding-top: 20px;
}

.inherit {
  color: inherit;
}