.bar {
  padding: 8px 12px;
  width: 100%;
}

.title {
  font: var(--font-text-large-bold);
  color: var(--color-text-heading);
}

.icon {
  margin-top: -25px;
  z-index: 1;
}

.cover {
  height: 60px;
  margin: 8px;
  width: calc(100% - 16px)
}

/*
* Pane Styles
*/
.cover {
  border-radius: calc(var(--border-radius-large) - 2px);
  height: 200px;
}