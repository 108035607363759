

.left, .right {
  flex-basis: 50%;
  background-color: var(--color-background);
}

.left, .right, .modal {
  min-height: calc(min(90vh, 1000px));
}

.left > *:last-child,
.right  >*:last-child {
  padding-bottom: 20px;
}

.modal.modal {
  max-width: 1200px;
  bottom: 50%
}

.fullHeight {
  height: 100%;
}