.pane {
  padding: 0 12px 0;
}

.menu {
  padding: 6px 0;
}

.header {
  padding: 6px 0;
}

.topEmpty:not(:first-child) {
  display: none;
}

.textbox {
  background-color: none;
  width: 100%;
  padding: 6px;
}