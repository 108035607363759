.scrollContainer {
  padding: 6px;
  max-height: 500px;
  overflow: auto;
}

.dropdown.dropdown {
  min-width: 400px;
}

.actions {
  padding-top: 6px;
  width: 100%;
}

.headerContainer {
  padding-left: 6px;
  padding-right: 6px;
}

.padded {
  margin-left: 16px;
  margin-right: 6px;
}

.selectedLabel {
  color: var(--color-primary);
}

.back {
  background: var(--color-background-tertiary);
}

.searchInput {
  margin-bottom: 4px;
}

.filterGroup {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}

.highlighted,
.highlighted > *,
.highlighted> * > * {
  color: var(--color-text-primary);
}

.inlineTable {
  display: inline-table;
}

.inline {
  display: inline;
}

.propertySelect.propertySelect {
  background: none;
}

.editing {
  margin-top: 20px;
}

.control {
  width: 100%;
  background: var(--color-background-tertiary);
  border-radius: var(--border-radius-small);
}