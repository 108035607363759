.tabbar {
  display: block;
  background-color: var(--color-background-tertiary);
  border: var(--border-secondary);
  border-radius: var(--border-radius-medium);
  padding: 0;
  padding: 3px;
}

.item {
  position: relative;
  border-radius: calc(var(--border-radius-medium) - 2px);
}

.item:not(.active) {
  border-color: transparent;
}

.item.active {
  background-color: var(--color-background);
  border-color: var(--color-border-secondary);
  box-shadow: var(--shadow-control);
}

.tablist {
  display: flex;
  flex: 1;
  list-style: none;
  align-items: center;
  padding: 0;
  border: none;
  outline: none;
  margin: 0;
}


.addOption {
  color: var(--color-text-secondary);
}

.unset {
  all: unset;
}