.header {
  padding: 10px 14px 0;
}

.container {
  padding: 10px 14px 10px;
  flex: 1 1 auto;
}

.container.inset {
  padding-left: 16px;
  padding-right: 16px;
}