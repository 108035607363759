.button {
  width: 100%;
  background: var(--color-background-secondary);
  border-radius: 4px;
}

.input {
  width: 100%;
  
  display: block;
  margin: 0;
}
