/* Keep only
durationOptions timeOption timeInput button durationOptions timeOption
*/

.timeOption {
  width: 70px;
  text-align: center;
}

.relative,
.timeInput {
  position: relative;
}

.timeInput .button {
  position: absolute;
  right: 0;
  top: 0;
}