.button {
  position: relative;
  padding: 12px 8px;
  border-radius: 2px;
  text-align: center;
  min-width: 80px;
  margin-bottom: 4px;
  display: flex;
  gap: 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.button.disabled {
  pointer-events: none;
}

.button.selected::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  border-radius: 6px;
  height: 4px;
  background-color: var(--color-primary);
}

.button:hover {
  background-color: var(--color-background-tertiary);
}

.button:active {
  background-color: var(--color-background-secondary);
}


.icon {
  color: var(--color-icon-strong);
}

.text {
  line-height: 110%;
}
    
/*
* Stat style button
*/
.stat {
  background-color: var(--color-background-tertiary);
  border-radius: 6px;
}

.stat:hover {
  background-color: var(--color-subtle-hover);
}