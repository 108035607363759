.container {
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.container.centered {
  flex-direction: column;
  justify-content: center;
  padding-bottom: 40vh;
}

/* TODO: Move to global vars */
@media (max-width: 800px) {
  .container.centered {
    padding-bottom: 20px;
  }
}

.container.sideStack {
  flex-direction: row;
}

.container.topStack {
  flex-direction: column;
}

.container.root {
  height: 100vh;
  width: 100vw;
}

.main {
  display: flex;
  flex: 1 1 auto;
  overflow: auto;
  overscroll-behavior: contain;
  height: 100%;
}

.sideStack .main {
  height: 100%;
}

.topStack .main {
  width: 100%;
}

.sidebar {
  height: 100%;
  flex: 0 0 auto;
  overflow: hidden auto;
  width: 500px;
}

.topbar {
  width: 100%;
  height: auto;
  flex: 0 0 auto;
  overflow: hidden auto;
}

.primaryNav {
  height: 100%;
  flex: 0 0 auto;
  overflow: hidden auto;
  display: contents;
}

.primaryNav:empty {
  display: none;
}
