.square {
  border-radius: calc(round(26%, 1px));
}

.circle {
  border-radius: 100%;
}

.diamond {
  transform: rotate(45deg);
}

.diamond>* {
  transform: rotate(-45deg);
}

.hideIcon>* {
  opacity: 0;
}