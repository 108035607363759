.bar {
  padding: 8px 12px;
  width: 100%;
}

.control {
  background-color: var(--color-background-tertiary);
}

.body {
  min-height: 250px;
}