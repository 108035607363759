.group {
  display: flex;
  flex-direction: row;
  column-gap: 0;
  flex-wrap: nowrap;
  font: var(--font-text);
  border: var(--border);
  border-radius: var(--border-radius-medium);
}

.full {
  width: 100%;
  justify-content: "space-between",
}

.full .button {
  width: 100%;
  text-align: center;
}

.button {
  border: none;
}

.button + .button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: var(--border);
}

.button:not(:last-child) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}