.list {
  width: 100%;
}

.group + .group {
  margin-top: 10px
}

.stackWrap {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.stackWrap > * {
  display: block !important;
  min-width: 220px;
  width: calc(33% - 6px);
  max-width: 280px;
}