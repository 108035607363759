.triagePaneContainer {
  height: 80px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.sticky.sticky {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 50vh;
  box-shadow: var(--shadow-popover);
  transition: all 0.3s ease;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.sticky.open {
  top: calc(80px - 50vh);
}

.icon {
  transition: all 0.3s ease;
}

.icon.rotate {
  transform: rotate(180deg);
}