.header {
  padding: 6px;
}

.colorPicker>div>div:first-child {
  display: none;
}

.swatch {
  width: 100%;
  height: 100%;
}

.swatchPicker {
  height: 20px;
  width: 40px;
  border-radius: 2px;
}

.swatchPicker.hover,
.swatchPicker:hover {
  outline: 2px solid var(--color-primary);
}

.swatchPicker:active {
  filter: brightness(0.5);
}