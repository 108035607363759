.container {
  position: relative;
  display: block;
  width: 100%;
  overflow: visible;
  padding-left: 2px;
  align-items: baseline;
}

.input.input {
  font: var(--font-text);
  border: none;
  padding: 0;
  width: 100%;
  background: transparent;
}

.input.medium {
  font: var(--font-text-medium);
}

.input:hover {
  background: transparent;
}

.insetRight {
  margin-right: -4px;
}

.inherit {
  color: inherit;
}

.invisible.invisible {
  opacity: 0;
}