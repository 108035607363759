.cover {
  width: 100%;
  height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
  border-radius: inherit;
}

.imageGradient:after {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  opacity: 0.2;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 53.69%, rgba(0, 0, 0, 0.3) 70%, rgba(0, 0, 0, 0.5) 100%);
}


.colorBg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  border-radius: inherit;
  overflow: hidden;
  backdrop-filter: blur(10px);
}


.colorBg::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  opacity: 1;
  overflow: hidden;
  border-radius: inherit;
}

.colorBg div {
  position: absolute;
  background-blend-mode: multiply;
  opacity: 0.8;
  filter: brightness(var(--brightness));
}

.colorBg {
  background-color: var(--color-background-secondary);
}