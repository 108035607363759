
.popover {
  min-width: 250px;
  max-height: calc((min(30vh, 250px)));
  overflow-y: auto;
  overflow-x: visible;
}

.icon {
  background-color: var(--color-background-tertiary);
  border: 4px solid transparent;
  border-radius: 6px;
  box-sizing: content-box;
  padding: 10px;
}