.header {
  position: relative;
  padding: 10px 16px;
}

.header.none {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}

.header.border::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background-color: var(--color-border-secondary);
}

.header.border.inset::after {
  left: 16px;
  right: 16px;
}