.trailing {
  width: 100%;
}

.trailing.large {
  height: 6em;
}

.trailing.xlarge {
  height: 12em;
}

.trailing.small {
  height: 2em;
}

.newLineMenu {
  max-width: 100%;
  width: auto;
  width: 100%;
}

.newLineButton {
  color: var(--color-text-placeholder)
}

@keyframes interactDelay {
  0% {
    pointer-events: none;
  }

  99% {}

  100% {
    pointer-events: initial;
  }
}

.interactDelay {
  animation: interactDelay 0.5s forwards;
}

.bubbleMenu {
  background: var(--color-background);
  border: var(--border);
  border-radius: 6px;
  box-shadow: var(--shadow-elevation-high);
  padding: 4px;
  width: auto;
  display: flex;
  align-items: center;
  justify-items: center;
}

.bubbleDivider {
  margin: 0 2px;
}