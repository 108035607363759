.container {
  justify-content: center;
  align-items: center;
}

.image {
  width: 80%;
  height: 80%;
  min-width: 160px;
  min-height: 160px;
  max-height: 180px;
  max-width: 180px;
}

.text {
  color: var(--color-text-placeholder);
}