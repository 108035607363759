.banner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-radius: inherit;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: var(--color-primary);
  font: var(--font-text-small-bold);
  text-transform: uppercase;
  color: var(--color-text-inverse);
  text-align: center;
}

.emptyMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  font: var(--font-text-medium);
  color: var(--color-text-placeholder);
  padding: 0 10px;
  pointer-events: none;
}