/* Resource Item Styles */

.note {
  display: block;
  position: relative;
  border-radius: var(--border-radius-medium);
  transition: box-shadow 200ms ease;
  padding: 8px 6px 8px 10px;
  width: 100%;
  flex: 0 0 auto;
  border: var(--border);
  border-color: var(--color-invisible);
}

.note:hover {
  background-color: var(--color-background);
  border: var(--border);
  box-shadow: var(--shadow-elevation-mid);
}

.note.seen:not(.selected) .discussionBody,
.note.seen:not(.selected) .person {
  color: var(--color-text-secondary);
}

.note.selected {
  background: var(--color-subtle-primary);
}

.discussionBody {
  pointer-events: none;
  /* For safari */
  text-overflow: ellipsis;
  overflow: hidden;
  font: var(--font-text-medium);
  letter-spacing: -0.2px;
  line-height: 1.3;
  max-height: 2.6em;
  /* For webkit supported browsers */
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
}

.unreadIndicator {
  position: absolute;
  top: 10px;
  right: 10px;
}

.pin {
  opacity: 0;
  transition: all 200ms ease;
  max-width: 0;
}

.pin.pinned {
  opacity: 1;
  max-width: 44px;
}

.pin.pinned .icon {
  color: var(--color-text);
}

.note:hover .pin {
  opacity: 1;
  max-width: 44px;
}

.relation.relation {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  font: var(--font-text);
  color: var(--color-text-secondary);
  text-wrap: nowrap;
}

.sentence {
  display: block;
  text-overflow: ellipsis;
  overflow: visible;
  white-space: no-wrap;
  color: var(--color-text-secondary);
}

.sentence > *,
.sentence > * > * {
  display: inline;
  margin: 0 !important;
  padding: 0 !important;
}

.creator {
  display: inline-flex;
  margin: 0 4px;
  vertical-align: baseline;
  font: inherit;
  color: inherit;
}