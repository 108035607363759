.button {
  width: 100%;
  min-width: 0;
}

.button.open {
  background: var(--color-subtle-active) !important;
}

.dropdown {
  background: var(--color-background);
  border: var(--border);
  border-radius: 6px;
  box-shadow: var(--shadow-elevation-high);
  padding: 4px;
  margin-top: 3px;
  min-width: 100%;
  max-width: 328px;
}