.rootContainer {
  padding: 10px;
  min-height: 100%;
  min-width: 100%;
  height: fit-content;
}

.groupContainer.columns {
  display: flex;
  flex-direction: row;
  height: 100%;
  gap: 10px;
}

.groupContainer.columns .group {
  min-width: calc(min(50vw, 600px));
}

.groupContainer.rows {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 10px;
}


.listContainer {
  padding: 0;
  padding-top: 2px;
  height: 100%;
}

.addInput {
  opacity: 0.2;
}

.newSection:hover .addInput,
.group:hover .addInput,
.listContainer:focus-within .addInput,
.listContainer:hover .addInput {
  opacity: 1;
}

.cardContainer {
  padding: 6px;
  margin-top: -2px;
  border-radius: var(--border-radius-small);
  background: var(--color-background-secondary);
  display: flex;
  row-gap: 6px;
  flex-direction: column;
}

.group {
  width: 100%;
}

.strike {
  text-decoration: line-through;
}

.groupHeader {
  margin: 0;
  width: 100%;
  position: relative;
}

.groupHeader:hover .headerActions {
  opacity: 1;
}

.hiddenGroups.hiddenGroups {
  display: flex;
  flex-direction: row;
}