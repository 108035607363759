.bar {
  padding: 4px;
  width: 100%;
}

.title {
  font: var(--font-text-medium-bold);
  color: var(--color-text-heading);
}

.icon {
  margin-top: -25px;
  z-index: 1;
}

.container {
  padding-top: 80px;
}

.dropdown {
  width: 100%;
}
