.heading {
  font: var(--font-heading);
  color: var(--color-text-heading);
  display: block;
  margin: 0;
}

.heading.bold {
  font: var(--font-heading-bold);
}

.textXLarge {
  font: var(--font-text-x-large);
  color: var(--color-text-heading);
  display: inline;
  margin: 0;
}

.textLarge {
  font: var(--font-text-large);
  color: var(--color-text-heading);
  display: inline;
  margin: 0;
}

.textMedium {
  font: var(--font-text-medium);
  color: var(--color-text);
  display: inline;
  margin: 0;
}

.textSmall {
  font: var(--font-text-small);
  color: var(--color-text);
  display: inline;
  margin: 0;
}

.text {
  font: var(--font-text);
  color: var(--color-text-heading);
  display: inline;
  margin: 0;
}

.bold {
  font-weight: 600;
}

.subtle {
  color: var(--color-text-secondary);
}

.placeholder {
  color: var(--color-text-placeholder);
}

/* Colors */

.inverse {
  color: var(--color-text-inverse);
}

.ai {
  color: var(--color-ai);
}

.pink {
  color: var(--color-user-pink);
}

.brown {
  color: var(--color-user-brown);
}

.green {
  color: var(--color-user-green);
}

.blue {
  color: var(--color-user-blue);
}

.purple {
  color: var(--color-user-purple);
}

.orange {
  color: var(--color-user-orange);
}

.red {
  color: var(--color-user-red);
}

.yellow {
  color: var(--color-user-yellow);
}

.gray {
  color: var(--color-text);
}