.panel {
  margin-top: 30px;
}

.heading {
  font: var(--font-heading-bold);
  color: var(--color-text-heading);
}

.secondary {
  color: var(--color-text-secondary);
}

.location {
  margin-left: -8px;
}