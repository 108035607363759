.button {
  margin-left: -4px;
  margin-right: 0px;
}

.button > * > * {
  text-overflow: initial;
  overflow: visible;
}

.code,
.code > * {
  overflow: visible;
  letter-spacing: 0;
  flex: 0 0 auto;
  text-align: center;
  display: flex;
  width: 32px;
  overflow: visible;
  white-space: nowrap;
  text-overflow: none;
}

.digit {
  margin: 0 -0.3px;
  display: inline-block;
  overflow: visible;
  text-align: center;
  flex-grow: 1;
}