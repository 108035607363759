.pane {
  display: flex;
  flex-direction: column;
  column-gap: 0;
  justify-content: stretch;
}

.paneContainer {
  padding: 10px 16px 10px;
  flex: 1 1 auto;
}

.paneActions {
  padding: 10px 15px;
  padding-top: 0;
}

/* IconHeader Header */
.black {
  color: var(--color-icon-strong);
}

.noMarg {
  margin: 0;
  padding: 0;
}

.weak {
  color: var(--color-text-secondary);
}