.stack>* {
  transition: margin-left 0.2s var(--snappy-bezier);
}

.stack > * {
  margin-left: -4px
}

.stack.xlarge > * {
  margin-left: -10px
}

.stack > :first-child {
  margin-left: 0;
}

.stack > *:hover + * {
  margin-left: 0;
}