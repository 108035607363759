.addSubtask {
  padding-left: 20px;
}

.peakOut {
  opacity: 0.5;
}


.upper {
  min-width: 0;
}

.middle {
  min-width: 0;
}

.rowDetails {
  padding-left: 28px;
}

.title {
  color: var(--color-text-heading);
  font: var(--font-text);
  padding: 5px 0;
  max-width: 100%;
  min-width: 0;
}

.lock {
  margin-top: 2px;
}

.subtle {
  color: var(--color-text-secondary);
}