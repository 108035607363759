.button {
  width: 100%;
  min-width: 0;
}

.button.open {
  background: var(--color-subtle-active) !important;
}

.select {
  background: var(--color-background);
  border: var(--border-invisible);
  border-radius: 6px;
}

.defaultPopover {
  min-width: 300px;
}

.select :global(.select__control) {
  background: var(--color-background);
  border: none;
  border-radius: 4px;
  box-shadow: none;
  padding: 0;
  min-height: 0;
}

.select :global(.select__control):hover {
  border: none;
  background: var(--color-subtle-hover);
}

.select :global(.select__control--is-focused),
.select :global(.select__control--is-focused):hover,
.select :global(.select__control--is-menu-open),
.select :global(.select__control--is-menu-open):hover {
  border: none;
}

.select :global(.select__control--is-focused),
.select :global(.select__control--is-focused):hover {
  background: var(--color-background);
}

.select :global(.select__placeholder) {
  font: var(--font-text);
  color: var(--color-text-placeholder);
}

.select :global(.select__value-container) {
  padding: 4px 6px;
  background: var(--color-background-tertiary);
  border: var(--border-invisible);
  border-radius: 7px;
}

.select :global(.select__value-container):focus-within {
  background: var(--color-background);
  border-color: var(--color-primary);
}

.select :global(.select__menu-list) {
  padding: 0;
}

.select.noSearch :global(.select__control) {
  display: none;
}


.select :global(.select__input-container) {
  padding: 0;
  margin: 0;
  font: var(--font-text);
  color: var(--color-text);
}

.tag {
  padding-right: 22px;
}

.remove {
  margin-left: -22px;
}

.person {
  display: flex;
}

.value {
  grid-area: 1/1/2/3;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  
  color: var(--color-text);
}

.menuGroup.menuGroup {
 padding: 0 6px; 
}

.menuGroupHeading.menuGroupHeading {
  margin-bottom: 0;
  color: var(--color-text-tertiary);
}


.menuItem.focused {
  background: var(--color-background-secondary);
}

.menuItem.focused:active {
  background: var(--color-background-tertiary);
}

.menuItem.active {
  background-color: var(--color-subtle-primary-active);
}

.menuItem.active:active {
  background-color: var(--color-subtle-primary);
}


/*
* Emoji Select
*/
.emojiPopover {
  max-width: none;
  padding: 0
}

.emojiPopover > aside {
  border: none !important;
  margin: 0;
}

.emojiPickerButton {
  padding-top: 0;
  padding-bottom: 0;
}


/*
* Relation Select
*/

.relationSelect {
  min-width: 350px;
}

/*
* Person Select
*/

.personSelect {
  min-width: 250px;
}

/*
* Status Select
*/
.statusSelect {
  min-width: 200px;
}

.buttonHeader.buttonHeader {
  padding-left: 8px;
  padding-right: 16px;
}

/*
* Entity Select
*/

.sideDivider {
  margin-left: 6px;
  width: calc(100% - 6px);
  margin-top: 4px;
  margin-bottom: 4px;
}

.spitControl {
  position: initial !important;
}

.takeover {
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--color-background);
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding: 4px;
  overflow: auto;
}

/*
* Split Menu
*/

.splitPopover,
.entityPopover {
  max-width: 600px;
  min-width: 600px;
  width: 100vw;
  padding: 0;
}

.splitMenu {
  padding-top: 6px;
}

.splitMenu .top {
  padding: 6px 6px 8px;
  overflow-x: auto;
  overflow-y: hidden;
}

.splitMenu .left,
.splitMenu .right {
  padding: 6px;
  min-height: 180px;
  max-height: 300px;
  padding-top: 0;
  overflow: auto;
}

.splitMenu .left {
  padding-left: 0;
  width: 50%;
  max-width: 180px;
  border-right: var(--border);
}

.splitMenu .left:empty {
  display: none;
}

.splitMenu .right {
  padding-top: 0;
  width: 100%;
  min-width: 0;
}

.roundedButton.roundedButton {
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.control {
  background-color: var(--color-background-tertiary);
}