.templateSelect {
  padding-bottom: 0;
}

.templateSection {
  padding: 10px;
}

.list {
  padding: 0;
  background-color: none;
}