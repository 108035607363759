.container {
  position: relative;
}

.container.clipped {
  overflow: hidden;
}

.placeholder {
  color: var(--color-text-placeholder) !important;
  flex-grow: 1;
  width: 100%;
}

.value {
  color: var(--color-text);
  flex-grow: 1;
  width: 100%;
  text-align: left;
}

.noPadding {
  padding: 0;
}

.fullWidth {
  width: 100%;
}

.onHover {
  opacity: 0.5;
}

.value:hover .onHover {
  opacity: 1;
}

.disabled {
  pointer-events: none;
}


/*
* Labelled Version
*/
.labelled {
  border: var(--border);
  border-radius: var(--border-radius-medium);
}

.noBreak {
  white-space: nowrap;
}