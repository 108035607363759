.tag {
  display: inline-flex;
  width: auto;
  font: var(--font-text);
  color: var(--color-text);
  background: var(--color-background-secondary);
  padding: 0 6px;
  margin: 0;
  border-radius: 4px;
  letter-spacing: -0.2px;
  flex-direction: row;
  column-gap: 4px;
  align-items: center;
  vertical-align: top
}

/*
* Status Tag
*/

.status {
  border-radius: 100px;
  padding-left: 4px;
  padding-right: 8px;
  text-wrap: nowrap;
}
.status.noIcon {
  padding-left: 8px;
}

/*
* Colors
*/

.none {
  color: var(--color-text);
  background: var(--color-invisible);
}

.pink {
  color: var(--color-user-pink);
  background: var(--color-user-pink-5);
}
.brown {
  color: var(--color-user-brown);
  background: var(--color-user-brown-5);
}
.green {
  color: var(--color-user-green);
  background: var(--color-user-green-5);
}
.blue {
  color: var(--color-user-blue);
  background: var(--color-user-blue-5);
}
.purple {
  color: var(--color-user-purple);
  background: var(--color-user-purple-5);
}
.orange {
  color: var(--color-user-orange);
  background: var(--color-user-orange-5);
}
.red {
  color: var(--color-user-red);
  background: var(--color-user-red-5);
}
.yellow {
  color: var(--color-user-yellow);
  background: var(--color-user-yellow-5);
}

.gray {
  color: var(--color-text);
  background: var(--color-border);
}


/*
* Sizes
*/
.tag.small {
  font: var(--font-text-small);
  padding: 0 4px;
}