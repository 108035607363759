.hideEmpty:empty {
  display: none !important;
}

.noBottomPad {
  padding-bottom: 0;
}

.noPadTop {
  padding-top: 0;
}

.editor {
  padding-top: 20px;
}

.control {
  background-color: var(--color-background-tertiary);
}