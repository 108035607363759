.bar {
  padding: 4px;
  width: 100%;
}

.title {
  font: var(--font-text-medium-bold);
  color: var(--color-text-heading);
}

.icon {
  margin-top: -25px;
  z-index: 1;
}

.cover {
  height: 60px;
  margin: 8px;
  width: calc(100% - 16px)
}