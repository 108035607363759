.stackContainer {
  display: flex;
  padding: 10px;
  gap: 8px;
  min-height: 100%;
}

.stackContainer:not(.horizontal),
.stackContainer.vertical {
  flex-direction: column;
  width: 100%;
}

.stackContainer.horizontal {
  flex-direction: row;
  height: fit-content;
}

.ungrouped {
  position: relative;
  width: 100%;
}

.horItem {
  width: 33%;
}

.group {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 300px;
  width: 300px;
}

.group.horizontal {
  min-height: 100%;
  flex: 1 1 auto;
}

.group.vertical {
  width: 100%;
}



.subGroups {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.subGroup {
  position: relative;
}

.subGroup .groupItems {
  padding: 8px;
}

.subGroup.firstRow .groupColor {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.subGroup.firstColumn .groupColor {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}


/* Override GroupHeader to use local colouring */
.groupHeader.groupHeader {
  background: inherit;
  position: relative;
  margin-bottom: 8px;
}

.groupHeader .groupColor {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}


.groupItems {
  padding: 0 8px;
  display: flex;
  flex-direction: column;
  height: 100%;
  /* overflow-y: auto; */
}

.fillSpace {
  min-width: 0px;
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
}

.addInput {
  opacity: 0.2;
}

.ungroupedContainer:hover .addInput,
.groupItems:focus-within .addInput,
.groupItems:hover .addInput {
  opacity: 1;
}

.swimlanes {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.swimlane {
  width: 40px;
  padding-bottom: 0;
  position: relative;
  overflow: hidden;
}

.swimlane .groupColor {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.swimlane .label {
  transform: translate(-50%, -50%) rotate(-90deg);
  position: absolute;
  text-wrap: nowrap;
  top: 50%;
  left: 50%;
}

.spacer {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}