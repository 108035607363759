.menuItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 6px;
  border-radius: 6px;
  font: var(--font-text);
  color: var(--color-text);
  border: none;
  text-align: left;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  user-select: none;
  white-space: nowrap;
}


.menuItem:not(.disabled):global(.focus),
.menuItem:not(.disabled):focus {
  background: var(--color-subtle-hover);
}

.menuItem {
  background: none;
}

.menuItem:not(.disabled):global(.hover),
.menuItem:not(.disabled):hover {
  background: var(--color-subtle-hover);
}

.routeActive.routeActive,
.highlightShadow.selected {
  background-color: var(--color-background);
  border-color: var(--color-border-secondary);
  box-shadow: var(--shadow-control);
}

.highlightColor.selected {
  background: var(--color-subtle-primary);
}

.menuItem:not(.disabled).selected:global(.hover),
.menuItem:not(.disabled).selected:hover {
  background: var(--color-subtle-primary-hover);
}

.highlightColor:not(.disabled):global(.active),
.highlightColor:not(.disabled):active {
  background: var(--color-subtle-active);
}

.label.label {
  display: flex;
  font: inherit;
  color: inherit;
  max-width: 100%;
  color: var(--color-text);
}


.noIcon .text {
  margin-left: 0;
}


/*
* Check Menu Item
*/
.disabledCheck {
  filter: grayscale(100%);
}

/*
* Expandable Menu Item
*/

.iconContainer {
  display: inline-block;
  position: relative;
  overflow: visible;
  max-height: 24px;
}

.iconButton {
  position: absolute;
  top: 0;
  left: -2px;
  opacity: 0;
  height: 100%;
  overflow: hidden;
  padding: 2px;
}

.iconContainer:hover > :first-child {
  opacity: 0;
}
.iconContainer:hover .iconButton {
  opacity: 1;
}

/* 
* Collapsible Menu Item
* Number of times hack updated: 2
*/

.collapsed > div > div,
.collapsed > div {
  justify-content: center !important;
  text-align: center !important;
}

/*
* Add Inline Menu
*/

.inlineAdd {
  padding: 0;
  border: none;
  outline: none;
  background: transparent !important;
}