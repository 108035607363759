.bar {
  padding: 8px 12px;
  width: 100%;
}

.title {
  font: var(--font-text-large-bold);
  color: var(--color-text-heading);
}

.cover {
  height: 60px;
  margin: 8px;
  width: calc(100% - 16px)
}