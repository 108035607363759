.thread {
  width: 100%;
  flex: 1 1 auto;
}

.whiteHeader {
  padding: 10px;
  background: var(--color-background);
  border-bottom: var(--border-secondary);
}

.subtleHeader {
  padding: 10px;
  padding-bottom: 0;
  background: var(--color-background-tertiary);
}

.threadTitle {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}

.clip {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display:  block;
  max-height: calc(1em + 10px);
}

.messages {
  padding: 10px 15px;
  background: var(--color-background-tertiary);
  min-height: 400px;
}

.empty {
  padding: 20px 0;
  text-align: center;
}

.reply {
  width: 100%;
}

.bottom {
  background: var(--color-background);
  border-top: var(--border-secondary);
  position: sticky;
}