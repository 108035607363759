.button.button {
  margin-left: -4px;
  margin-right: -4px;
  border-radius: var(--border-radius-small);
  padding-left: 0;
  padding-right: 0px !important;
}

.inset {
  padding-left: 20px;
}