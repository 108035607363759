.listItem {
  padding: 2px 6px
}

.menuItem {
  padding: 2px 6px
}


.strikethrough {
  color: var(--color-text-secondary);
}

.strikethrough:not(:focus-within) {
  position: relative;
}

.strikethrough:not(:focus-within)::after {
  content: "";
  position: absolute;
  pointer-events: none;
  top: round(50%, 1px);
  left: 0;
  right: 0;
  height: 1px;
  background: var(--color-user-red);
}

.wrap {
  text-wrap: wrap;
}

.meetingPane {
  max-height: 40vh;
}

.actionCheck {
  border-radius: 12px;
}