.container {
  height: 100%;
}

.monthContainer {
  height: 100%;
  isolation: isolate;
  overflow-x: hidden;
  overflow-y: auto;
}

.monthRow {
  height: 100%;
  min-height: 16.6666666667%;
  height: auto;
  flex: 1 1 auto;
}

.dayOfWeek {
  padding: 6px;
  width: 14.2857142857%;
  overflow: hidden;
  flex: 1 1 auto;
}

.dayCell {
  padding: 6px;
  width: 14.2857142857%;
  border: var(--border);
  flex: 1 1 auto;
}

.monthRow:not(:first-child):not(:last-child) .dayCell {
  border-bottom: none;
}

.dayCell {
  border-left: none;
}

.dayCell:last-child {
  border-right: none;
}

.dayCell.dropping {
  outline: 2px solid var(--color-primary);
}

.dayCell.weekend {
  background-color: var(--color-background-tertiary);
}

.addButton {
  opacity: 0;
}

.dayCell:hover .addButton {
  opacity: 1;
}

.day.today {
  display: inline-block;
  border-radius: 100px;
  background-color: var(--color-user-red);
  min-width: 22px;
  text-align: center;
  color: var(--color-text-inverse);
}

.listItem {
  padding: 0;
  position: relative;
  max-width: 100%;
  width: 100%;
}

.menuItem {
  pointer-events: none;
}

.dayMenuGroup.dayMenuGroup {
  margin: 0;
  padding: 0 2px;
}

.fixedSpacer {
  position: relative;
  min-height: 32px;
  display: block;
  width: 100%;
}

.fixedItem {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  overflow: visible;
}

.fixedInvisible {
  opacity: 0;
  pointer-events: none;
}

.resizeIndicator {
  position: absolute;
  margin-left: -2px;
  margin-right: -2px;
  top: 0;
  bottom:0;
  width: 16px;
  height: 100%;
  cursor: col-resize;
}

.resizeIndicator:first-child {
  left: 0;
}

.resizeIndicator:last-child {
  right: 0;
}