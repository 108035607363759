.noHighlight {
  pointer-events: none;
}

.noBackground:hover,
.noBackground:active,
.noBackground {
  background: none;
  border: none;
  padding: 6px;
  box-shadow: none;
  margin: 0;
}