.message {
  display: block;
}

.avatar {
  align-self: flex-start;
  margin-top: 2px;
  display: block;
  width: 40px;
  min-width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: var(--border-radius-medium);
  background-color: var(--color-user-blue-2);
  text-align: center;
  color: var(--color-text-inverse);
  font-size: 16px;
  overflow: hidden;
}

.name {
  color: var(--color-text-heading);
  font: var(--font-text-bold);
}

.date {
  color: var(--color-text-tertiary);
  font: var(--font-text);
}

.body {
  font: var(--font-text-medium);
}