.card {
  padding: 6px 8px;
}

.avatar.avatar {
  width: 70px;
  height: 70px;
  min-height: 70px;
  min-width: 70px;
  line-height: 70px;
  border-radius: 100%;
}

.subline {
  max-width: calc(max(70%, 300px));
}