.editable {
  color: var(--color-text);
}

.subtle {
  color: var(--color-text-secondary);
}

.editable:empty:before {
  content: attr(data-placeholder);
  color: var(--color-text-placeholder);
}

.highlight:not(:focus):hover {
  background: var(--color-subtle-primary-hover);
  border-radius: 2px;
}

.highlight:focus,
.highlight:focus-within {
  background: var(--color-subtle-primary-active);
  border-radius: 4px;
  outline: 2px solid var(--color-subtle-primary-active);
}


.disabled {
  pointer-events: none;
}

/* Text Sizes */
.default {
  font: var(--font-text);
}
.small {
  font: var(--font-text-small);
}
.medium {
  font: var(--font-text-medium);
}

/* Single line ellipses */

.singleLine {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}