.seperator {
  font: var(--font-text);
  color: var(--color-text-tertiary);
}

.breadcrumb {
  white-space: nowrap;
}

.linkButton {
  max-width: 200px;
}

.disabled {
  pointer-events: none;
}