.rootGroup.rootGroup {
  padding: 0 6px;
  width: calc(100% + 6px);
}

.group {
  position: relative;
}

.group::after {
  content: '';
  position: absolute;
  top: 0;
  left: 4px;
  bottom: 0;
  width: 1px;
  background-color: var(--color-border);
}