.root {
  padding: 6px;
}

.head {
  padding-bottom: 8px;
  display: flex;
  justify-content: space-between;
}

.title {
  font: var(--font-text-bold);
  padding-left: 6px;
}

.dateInput {
  width: 100%;
}

.control {
  background-color: var(--color-background-tertiary);
}

.datePopover {
  width: auto;
  min-width: auto;
  max-width: auto;
}

.button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  position: relative;

  border-radius: 2px;
  width: 26px;
  height: 26px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.navButton {
  width: 26px;
  height: 26px;
  color: var(--color-icon);
}

.navButton:hover {
  background: var(--color-subtle-hover);
}

.navButton:active {
  background: var(--color-subtle-primary);
}

.navButton svg {
  width: 14px;
  height: 14px;
}

.day {
  width: 35px;
  height: 35px;
  color: var(--color-text);
}

.today:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: var(--color-user-red-2);
}
.today.morning:after {
  left: 20%
}
.today.afternoon:after {
  left: 70%
}

.day:hover {
  border-radius: 2px;
  background: var(--color-subtle-hover);
}

.daySelected {
  background: var(--color-primary);
  color: var(--color-text-inverse);
}

.daySelected:hover {
  background: var(--color-primary-hover);
  color: var(--color-text-inverse);
}

.dayInRange {
  background: var(--color-subtle-primary);
  color: var(--color-text);
}

.dayInRange:hover {
  background: var(--color-subtle-primary-hover);
  color: var(--color-text);
}

.dayOutside {
  color: var(--color-text-tertiary);
}

.cell {
  border-spacing: 0;
  padding: 0;
  box-sizing: border-box;
}

.headCell {
  font: var(--font-text-small);
  color: var(--color-text-tertiary);
  letter-spacing: 0.2px;
  text-transform: uppercase;
  margin: 0;
  box-sizing: border-box;
  padding: 0;
}
