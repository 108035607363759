.nav {
  border-right: var(--border-secondary);
  min-height: 100%;
  display: block;
  width: 260px;
  padding: 30px 20px 10px;
}

.viewPane.viewPane {
  border: none;
  border-radius: 0;
  padding: 0;
  box-shadow: none;
}

.half.half {
  min-width: calc(50% - 10px);
  width: calc(50% - 10px);
  flex-direction: row;
  background-color: var(--color-background);
}

.statHeading {
  margin-top: 20px;
}