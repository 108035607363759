.dropdown {
  box-shadow: var(--shadow-elevation-high);
  width: 328px;
}

.mention {
  display: inline-block;
  color: var(--color-primary);
  background: var(--color-subtle-primary-active);
  vertical-align: baseline;
  padding: 0px 6px 2px 3px;
  margin-left: -3px;
  margin-bottom: -2px;
  border-radius: 6px;
}

.mention:hover {
  color: var(--color-primary-hover);
}

.at {
  opacity: 0.7;
  display: inline-block;
  vertical-align: 1px;
  margin-top: -1px;
}

.inlineRelation {
  display: inline-flex;
  width: fit-content;
  font: inherit;
  cursor: default;
  padding-right: 4px;
  max-width: calc(min(100%, 40vw));
}


.inlineRelation div {
  position: relative;
}
.inlineRelation:not(.mention) div::after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  /* transform: translateY(-50%); */
  background: var(--color-text);
  opacity: 0.3;
  height: 1px;
}

.inlineRelation:hover div::after {
  opacity: 1;
}