.hideLast:last-child,
.divider + .divider {
  display: none;
}

.horizontal {
  position: relative;
  display: block;
  width: 100%;
  height: 1px;
  background: var(--color-border-secondary);
  opacity: 1;
  mix-blend-mode: multiply;
  border-radius: 1px;
  margin: 10px 0;
}


.vertical {
  display: block;
  width: 1px;
  margin: 0 10px;
  /* TODO: Help */
  height: 32px;
  background: var(--color-border-secondary);
  opacity: 0.8;
  border-radius: 1px;
}

.noMargin {
  margin: 0;
}


.label {
  font-size: 8px;
  line-height: 8px;
  color: var(--color-text-secondary);
  border-radius: 10px;
  background-color: var(--color-border-secondary);
  text-transform: uppercase;
  padding: 2px 5px;

  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.subtle {
  opacity: 0.5;
}