.parent, .target {
  display: contents;
}

.target.none:not(.show) > * {
  opacity: 0;
}

.target.partial:not(.show)>* {
  opacity: 0.2;
}

.parent:hover .target > * {
  opacity: 1;  
}