.card {
  cursor: default;
}

.card.selected {
  border-radius: var(--border-radius-small);
  outline: 1px solid var(--color-primary);
}

.menu {
  width: 33%;
}