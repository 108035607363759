/*
* Person Icon
*/

.person {
  position: relative;
  background: var(--color-user-gray-4);
  font: var(--font-text);
  color: var(--color-text-inverse);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.5px;
  line-height: 100%;
  font-size: 1em;
  font-weight: bold;
  width: 100%;
  height: 100%;
}

.person.large {
  font-size: calc(30px * 0.7);
}

.person.medium {
  font-size: calc(24px * 0.5);
}

.person.xlarge {
  font-size: calc(44px * 0.5);
}

.person.xxlarge {
  font-size: calc(80px * 0.5);
}

.person.default {
  font-size: calc(16px * 0.6);
}

.person.small {
  font-size: calc(15px * 0.5);
}

.person.xsmall {
  font-size: calc(14px * 0.5);
}

.person.circle {
  border-radius: 100px;
}

.person.square {
  border-radius: 4px;
}

.person.online::after {
  content: '';
  position: absolute;
  top: -4px;
  right: -4px;
  width: calc(min(max(36.36363636%, 8px), 14px));
  height: calc(min(max(36.36363636%, 8px), 14px));
  border: 2px solid var(--color-background);
  border-radius: 100px;
  background: var(--color-user-green);
}

.icon .person {
  width: 100%;
  height: 100%;
  font-size: 1em;
  line-height: 2em;
}

.image {
  display: block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.person .initials {
  color: var(--color-text-inverse);
  max-width: 100%;
  overflow: hidden;
  text-wrap: none;
  text-wrap: none;
}

.stack {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
}

.stack > * {
  margin-right: -5px;
  transition: margin-right 0.2s var(--snappy-bezier);
}

.stack.xlarge >* {
  margin-right: -10px;
}

.stack > *:hover {
  margin-right: 0;
}
