/*
* List Item
*/

.task {
  padding: 7px 6px;
}

.addSubtask {
  padding-left: 20px;
}

.peakOut {
  opacity: 0.5;
}

.notBig {
  max-width: 200px;
}


.upper {
  min-width: 0;
}

.middle {
  min-width: 0;
}

.alignText {
  display: flex;
  align-items: baseline;
  overflow: hidden;
  column-gap: 4px;
}

.rowDetails {
  padding-left: 28px;
  background-color: inherit;
}

.flip {
  transform: scaleX(-1);
}

.title {
  color: var(--color-text-heading);
  font: var(--font-text);
  padding: 5px 0;
  max-width: 100%;
  min-width: 0;
}

.lock {
  margin-top: 2px;
}

.subtle {
  color: var(--color-text-secondary);
}