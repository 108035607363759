.inline {
  display: inline-flex;
  vertical-align: bottom;
}

.button:hover .delete {
  opacity: 1;
}

.delete {
  opacity: 0.1;
}