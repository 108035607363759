.label {
  position: absolute;
  top: 0;
  right: 0;
}

.locked {
  position: relative;
  outline: 3px solid var(--color-primary);
  outline-offset: 3px;
  border-radius: 2px;
  display: block;
  width: 100%;
}

.unlocked {
  display: contents;
}