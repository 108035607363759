.noPointer {
  pointer-events: none;
}

.scaled {
  width: 130%;
  transform: scale(0.77);
  transform-origin: 0 0;
}

.noWrap {
  text-wrap: nowrap;
}