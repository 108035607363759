.button {
  border-radius: 1000px;
  padding-right: 9px;
}

.button.primary {
  border: 1px solid var(--color-subtle-primary-active);
  background: var(--color-subtle-primary-active);
}

.button.primary:hover {
  border: 1px solid var(--color-subtle-primary-active);
}

.button.secondary {
  color: var(--color-text);
  background: var(--color-background-secondary);
  border: var(--border-invisible);
}

.button.secondary:hover {
  background: var(--color-subtle-hover);
  color: var(--color-text);
}

.button.secondary:active {
  background: var(--color-subtle-active);
}

.counter {
  border-radius: 1000px;
}

/* offset icon padding inside button */
.text {
  margin-left: -2px;
}
