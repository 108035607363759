
.groupHeader {
  width: 100%;
}

.headerLabel > *,
.headerLabel {
  font: var(--font-text-bold);
  color: var(--color-text);
}

.hiddenGroup {
  display: flex;
  flex-direction: row;
  column-gap: 4px;
}

.label {
  display: block;
}

.groupCounter {
  display: block;
}

.groupColor {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius-medium);
  opacity: 0.2;
  pointer-events: none;
}

.groupColor.dropping {
  outline: 2px solid var(--color-primary);
}