.stackContainer {
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: var(--pane-gap);
  column-gap: var(--pane-gap);
  height: 100%;
  overflow: auto;
}

.wrapContainer {
  display: flex;
  padding: 10px;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: inherit;
  column-gap: var(--pane-gap);
  row-gap: var(--pane-gap);
  width: 100%;
}


.sheet {
  width: 100%;
  position: relative;
}

.sheet {
  border-radius: var(--border-radius-large);
  box-shadow: var(--shadow-elevation-high);
  overflow: auto;
  border: var(--border-tertiary);
}

.sheet.glassy {
  border: 1px solid var(--color-border-glass);
}

.sheet.sheet.sizingMode {
  background: none;
  box-shadow: none;
  border: none;
  overflow: visible !important;
}

.sheet.highTransparency {
  background: rgba(var(--color-rgb-background), 0.2);
  backdrop-filter: blur(5px);
}

.sheet.interactable.highTransparency:hover,
.sheet.interactable.highTransparency:focus-within,

.sheet.midTransparency {
  background: rgba(var(--color-rgb-background), 0.45);
  backdrop-filter: blur(6.8px);
}

.sheet.interactable.highTransparency:active,
.sheet.interactable.midTransparency:hover,
.sheet.interactable.midTransparency:focus-within,

.sheet.lowTransparency {
  background: rgba(var(--color-rgb-background), 0.84);
  backdrop-filter: blur(9.6px);
}

.sheet.interactable.lowTransparency:focus-within {
  background: rgba(var(--color-rgb-background), 0.99);
}

.sheet.noneTransparency {
  background: var(--color-background);
}


.stackSheets.containerY,
.sheet.containerY {
  height: 100%;
}

.sheet.contentY {
  height: auto;
  max-height: 100%;
}

.stackSheets.full,
.sheet.full {
  flex-grow: 1;
  width: 100%;
}

.stackSheets.primary,
.sheet.primary {
  width: 70vw;
  max-width: 980px;
  min-width: 500px;
  padding-bottom: 30vh;
}


.stackSheets.content,
.sheet.content {
  width: auto;
  min-width: fit-content;
}

.stackSheets.primaryThicc,
.sheet.primaryThicc {
  width: 100vw;
  max-width: 1240px;
}

.stackSheets.primaryThin,
.sheet.primaryThin {
  width: 100vw;
  max-width: 740px;
}

.stackSheets.secondary,
.sheet.secondary {
  width: 40Vw;
  min-width: 440px;
  max-width: 640px;
}

.stackSheets {
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: var(--pane-gap);
  justify-content: flex-start;
  align-items: flex-start;
}

.stackSheets .sheet {
  width: 100%;
}


/*
* Focusable Sheets
*/
.focusable {
  transition: flex-grow 0.5s ease, background-color 0.5s ease, box-shadow 0.3s ease;
  box-shadow: var(--shadow-elevation-mid);
  background: var(--color-background-tertiary);
}

.focusable:not(.focused):hover {
  box-shadow: var(--shadow-elevation-high);
}

.focusable.focused {
  flex-grow: 1;
  box-shadow: var(--shadow-elevation-high);
}

/*
* Overlay Sheets
*/

@keyframes slideIn {
  0% {
    transform: translateX(80%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
      opacity: 0;
  }

  100% {
    opacity: 0.25;
  }
}
.overlaySheet {
  position: absolute;
  top: 10px;
  right: 10px;
  animation: slideIn 300ms;
  background: var(--color-background);
  box-shadow: var(--shadow-popover);
}

.overlaySheetDimmer {
  position: fixed;
  display: block;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  animation: fadeIn 300ms;
  animation-fill-mode: forwards;
  background: radial-gradient(58.63% 58.63% at 90% 51.78%, rgba(0, 29, 55, 1) 0%, rgba(0, 29, 55, 0.8) 38.42%, rgba(0, 29, 55, 0.4) 100%);
}


.slideInSheet {
  padding: 10px;
  position: fixed;
  top: 8px;
  left: 100%;
  bottom: 8px;
  width: auto;
  height: calc(100% - 16px);
  opacity: 0;
  transition: transform 200ms var(--snappy-bezier), opacity 400ms var(--snappy-bezier);
  box-shadow: var(--shadow-popover);
}

.slideInSheet.containerY {
  height: calc(100% - 16px);
}

.slideInSheet:focus-within,
.slideInSheet.visible {
  transform: translateX(calc(-100% - 10px));
  opacity: 1;
}

.slideInSheet > .contents {
  background-color: white;
  border-radius: 8px;
  width: 100%;
  height: 100%;
}

