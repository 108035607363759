.card {
  list-style: none;
  position: relative;
  border-radius: 4px;
  min-width: 0;
  width: 100%;
  transition: opacity 100ms ease;
  border-radius: 6px;
  padding: 7px 6px;
  transition: box-shadow 50ms ease-in-out;
  border: var(--border-secondary);
  border-radius: var(--border-radius-medium);
  box-shadow: var(--shadow-elevation-low);
  background-color: var(--color-background);
  overflow: hidden;
}

.card.selectable:active,
.card.selectable.selected {
  background: var(--color-subtle-primary);
  border-color: var(--color-primary);
  outline: 1px solid var(--color-primary);
}

.card.clickable:hover {
  box-shadow: var(--shadow-elevation-mid);
}

.noPadding {
  padding: 0;
}