
.container {
  position: relative;
  display: inherit;
  isolation: isolate;
}

.highlight {
  content: "";
  padding: 0.1em 0.4em;
  position: absolute;
  z-index: 0;
  left: -4px;
  bottom: -0.1em;
  width: 0%;
  height: 110%;
  opacity: 0.4;
  width: calc(100% + 8px);
  transform: skewX(10deg);
  border-radius: 4px;
}

.container > :not(.highlight) {
  z-index: 1;
  color: inherit;
}