
.ellipsis {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  overflow-y: visible;
  max-width: 100%;
}

.ellipsis > div > div > div > div,
.ellipsis > div > div > div,
.ellipsis > div > div,
.ellipsis > div {
  display: inline;
  white-space: nowrap;
  max-width: 100%;
}

.textStack {
  vertical-align: baseline;
}

.textStack > * + * {
  display: inline-block;
  margin-left: 4px;
}