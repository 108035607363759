.modal.modal {
  position: fixed;
  top: initial;
  left: initial;
  bottom: 10px;
  right: 10px;
}


.primary {
  color: var(--color-text-primary);
}

.header {
  width: 350px;
  margin-top: -10px;
  margin-bottom: -10px;
}

.header.open {
  width: 450px;
}

.oneLine {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.previews {
  max-height: 70vh;
  overflow-y: auto;
}

.props.props {
  background: none;
  padding: 0;
  width: 100%;
}

.navigate {
  text-align: center;
}