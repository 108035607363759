/* Workflow Styles */

.workflowStep {
  overflow: visible;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 48px;
}

.workflowStep,
.workflowStep * {
  cursor: pointer;
}

.iconContainer {
  position: relative;
}

.assignedIcon {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  outline: 2px solid var(--color-background);
  border-radius: 100%;
}

.workflowStep.selected .icon {
  box-shadow: 0 0 0 4px var(--color-primary);
  animation: pulse-border 2s ease-in-out infinite;
}

@keyframes pulse-border {
  0% {
    box-shadow: 0 0 0 1.5px var(--color-primary);
  }

  50% {
    box-shadow: 0 0 0 4px var(--color-primary);
  }

  100% {
    box-shadow: 0 0 0 1.5px var(--color-primary);
  }
}

.workflowStep .icon {
  width: 48px;
  height: 48px;
  /* Icon */
  /* background: radial-gradient(50% 50% at 50% 50%, #2D90FA 0%, #2983E4 100%); */
  box-shadow: var(--shadow-elevation-mid);
}

.workflowStep .text {
  text-align: center;
  max-width: 100%;
  text-wrap: nowrap;
}

.addNext {
  position: absolute;
  top: 110%;
}

.addButton {
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: var(--border);
  border-radius: 100px;
  background-color: var(--color-background);
  box-shadow: var(--shadow-elevation-low);
  transition: transform 0.2s var(--snappy-bezier);
  opacity: 0;
  cursor: pointer !important;
}

.addButton * {
  pointer-events: none;
}

.workflowStep:hover .addButton {
  opacity: 1;
}

.fixedRight {
  position: absolute;
  right: -14px;
  top: 26px;
}

.addButton:hover {
  opacity: 1;
  background-color: var(--color-background);
  box-shadow: var(--shadow-elevation-mid);
}

.addButton:active {
  background-color: var(--color-background);
  box-shadow: var(--shadow-elevation-low);
}

.addButton.fixedRight {
  right: -64px;
}

.outputPreview {
  max-width: 400px;
  padding: 8px 0;
}

.outputPreview,
.outputPreview .label {
  color: var(--color-text-inverse);
}

.outputPreview .relations {
  max-height: 500px;
  overflow: auto;
}