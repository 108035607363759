.dialog {
  max-width: min(800px, calc(100vw - 20px));
  width: 100vw;
  height: 600px;
}

.dialog.passive {
  max-width: min(500px, calc(100vw - 20px));
}

.container {
  align-items: flex-start;
  column-gap: 0;
  row-gap: 0;
}

.content {
  overflow: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 100%;
  width: 100%;
}

.fullWidth {
  width: 100%;
}

.content {
  height: 100%;
  overflow-x: visible;
  padding: 20px;
  padding-bottom: 0;
}

.actions {
  padding: 20px;
  padding-top: 0;
}

.dialog.split {
  /* Mid transparency */
  background: linear-gradient(180deg, rgba(var(--color-rgb-background), 0.45), rgba(var(--color-rgb-background), 0.65));
  backdrop-filter: blur(20px);
}

.splitLeft {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  height: 600px;
  flex-basis: 35%;
  overflow-y: auto;
}

.splitRight {
  /* Low transparency */
  overflow-y: auto;
  padding: 0;
  height: 600px;
  flex-basis: 65%;
}

/* TODO: Move to global vars */
@media (max-width: 800px) {
  .splitLeft {
    display: none !important;
  }

  .splitRight {
    flex-basis: 100%;
  }
}

.split.blocking .splitRight {
  border-left: var(--border);
  background: rgba(var(--color-rgb-background), 0.7);
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}

.splitRight:first-child {
  width: 100%;
  flex-basis: 100%;
}