
.background {
  background-color: var(--color-background);
}

.icon {
  margin-top: -25px;
  z-index: 1;
}

.cover {
  height: 60px;
  margin: 8px;
  width: calc(100% - 16px)
}

.control {
  background-color: var(--color-background-tertiary);
  width: 100%;
  border-radius: var(--border-radius-small);
}