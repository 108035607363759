.entitySheet {
  padding-bottom: 0 !important;
}

.wrapper {
  display: flex;
  flex-direction: row;
  max-height: 100%;
  overflow: hidden;
}

.content {
  width: 100%;
  max-height: 100%;
  overflow: auto;
  /* So can see stuff at bottom easily */
  padding-bottom: 20vh;
}

.side {
  min-height: 100%;
}

.paneIcons {
  padding-left: 0;
  padding-right: 20px;
}