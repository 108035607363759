.flow {
  height: 100%;
  width: 100%;
}

.controlButton svg {
  fill: var(--color-text);
  max-width: 20px;
  max-height: 20px;
}

.entity {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: visible;
  max-width: 200px;
  padding: 5px;
}

.entity:not(:hover) .handle {
  opacity: 0;
}

.entityCard {
  position: relative;
  max-width: 200px;
  width: 250px;
  isolation: isolate;
    /* New stacking context */
}

.entityCard:not(:hover) .handle {
  opacity: 0;
}

.clip {
  overflow: hidden;
}


.whole {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: none;
  border-radius: 0;
  transform: none;
}


.edge :global(.react-flow__edge-path) {
  border-color: var(--color-background);
}

.group {
  position: relative;
  min-width: 200px;
  min-height: 200px;
  height: 100%;
  width: 100%;
}

.outline {
  position: absolute;
  border: var(--border);
  border-width: 2px;
  border-radius: 8px;
  border-style: dashed;
}

.heading {
  padding-left: 10px;
  width: auto;
}

.flow :global(.react-flow__node-group) {
  border: none;
  margin: 0;
  padding: 0;
  outline: none;
}

.label {
  background: var(--color-border);
  border-radius: 100px;
  color: white;
  padding: 0 4px;
  line-height: 1.3em;
  font-size: 14px;
}


/* Handle Styles */

.invisibleHandle {
  opacity: 0;
  pointer-events: none;
}

.handle {
  width: 6px;
  height: 6px;
  background: var(--color-background);
  border: 3px solid var(--color-primary);
  border-radius: 10px;
  box-sizing: content-box;
  opacity: 0;
  z-index: 1;
}

.handle.top {
  top: -12px;
}
.handle.bottom {
  bottom: -12px;
}

.handle:hover,
.handle.active {
  opacity: 1;
}

.alwaysVisible {
  opacity: 1;
}