.header {
  min-height: 34px;
  margin-bottom: 10px;
}

.button {
  position: relative;
}

@keyframes pulse {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-6px);
  }
  75% {
    transform: translateX(3);
  }
  85% {
    transform: translateX(-2);
  }
  100% {
    transform: translateX(0);
  }
}

.button:hover .iconRight {
  animation: pulse 0.5s ease-in-out;
  opacity: 1;
}