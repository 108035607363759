.container {
  width: 100%;
}

.clipped {
  padding: 0 5px;
  margin: 0 -5px;
  overflow-x: visible;
  overflow-y: hidden;
  position: relative;
  isolation: isolate; /* New stacking context */
  -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0));
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0));
}

.clipped.small {
  max-height: 100px;
}
.clipped.medium {
  max-height: 400px;
}
.clipped.large {
  max-height: 600px;
}