.divider {
  height: calc(100% - 40px);
  padding: 20px 0;
}

.main {
  width: 100%;
}

.main.left {
  background-color: var(--color-background);
}

.control {
  background-color: var(--color-background-tertiary);
  width: 100%;
}

.fieldLabel {
  font: var(--font-text-bold);
}

.fieldDesc {
  color: var(--color-text-secondary);
}

.centerd {
  padding-bottom: 20%;
}

.connectionCell {
  width: 50%;
}