.container {
  padding: 8px;
}

.picker {
  display: flex;
  padding: 0;
}

.popover {
  min-width: 230px;
}

.input {
  min-width: 0;
  width: 60px;
}

.button {
  width: 100%;
  background: var(--color-background-secondary);
  border-radius: 4px;
}


.faded {
  opacity: 0.8;
}
