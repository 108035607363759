
.floating {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: var(--color-background);
  box-shadow: var(--shadow-elevation-high);
  border-radius: 100px;
  max-width: 600px;
  padding: 8px 12px;
  /* Copied from other reactflow controls */
  z-index: 5; 
}
