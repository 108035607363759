.container {
  box-sizing: border-box;
  position: relative;
}

.centered {
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
}

/* Full padding */

.verticalPadded,
.bothPadded,
.topPadded {
  padding-top: 20px;
}

.verticalPadded,
.bothPadded,
.bottomPadded {
  padding-bottom: 20px;
}

.horizontalPadded,
.bothPadded,
.rightPadded {
  padding-right: 20px;
}

.horizontalPadded,
.bothPadded,
.leftPadded {
  padding-left: 20px;
}


/* Half Padding */
.halfSize.verticalPadded,
.halfSize.bothPadded,
.halfSize.topPadded {
  padding-top: 10px;
}

.halfSize.verticalPadded,
.halfSize.bothPadded,
.halfSize.bottomPadded {
  padding-bottom: 10px;
}

.halfSize.horizontalPadded,
.halfSize.bothPadded,
.halfSize.rightPadded {
  padding-right: 10px;
}

.halfSize.horizontalPadded,
.halfSize.bothPadded,
.halfSize.leftPadded {
  padding-left: 10px;
}

/* Double Padding */
.doubleSize.verticalPadded,
.doubleSize.bothPadded,
.doubleSize.topPadded {
  padding-top: 40px;
}

.doubleSize.verticalPadded,
.doubleSize.bothPadded,
.doubleSize.bottomPadded {
  padding-bottom: 40px;
}

.doubleSize.horizontalPadded,
.doubleSize.bothPadded,
.doubleSize.rightPadded {
  padding-right: 40px;
}

.doubleSize.horizontalPadded,
.doubleSize.bothPadded,
.doubleSize.leftPadded {
  padding-left: 40px;
}



/* Margin Insets */

.topInset,
.verticalInset,
.bothInset {
  margin-top: -4px;
}

.bottomInset,
.verticalInset,
.bothInset {
  margin-bottom: -4px;
}

.leftInset,
.horizontalInset,
.bothInset {
  margin-left: -4px;
  width: calc(100% + 2px);
}

.rightInset,
.horizontalInset,
.bothInset {
  margin-right: -4px;
  width: calc(100% + 2px);
}

.bothInset,
.horizontalInset {
  width: calc(100% + 4px);
}
