.card {
  padding: 6px 8px;
}

.avatar.avatar {
  width: 60px;
  height: 60px;
  min-height: 60px;
  min-width: 60px;
  line-height: 60px;
  border-radius: 100%;
}