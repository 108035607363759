.group {
  display: block;
  padding: 0;
  width: 100%;
  margin: 0;
}

.group .group {
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
  padding: 0 12px;
}

.group + .group {
  margin-top: 12px;
}

.group:empty {
  display: none;
}

.group:first-child {
  margin-top: 0;
}

.group:last-child {
  margin-bottom: 0;
}

.header {
  padding-bottom: 4px;
}

.title.title {
  text-wrap: nowrap;
  flex: initial;
  margin: 0;
  max-width: 100%;
}

.items {
  display: block;
  margin: 0;
}

.group.inset .items {
  margin: 0 -10px;
  width: calc(100% + 12px);
  padding: 0 4px;
}

.clickable {
  position: relative;
}

.clickable:hover:after {
  content: '';
  position: absolute;
  top: 0;
  right: -4px;
  left: -4px;
  bottom: 0;
  background-color: var(--color-subtle-hover);
  border-radius: 100px;
}