.container {
  position: relative;
}

.insertBefore {
  content: "";
  height: 2px;
  width: 100%;
  position: absolute;
  left: 0;
  top: attr(data-offset);
  background: var(--color-primary);
}
