
.tooltip {
  pointer-events: none;
  user-select: none;
  padding: 3px 8px;
  color: white;
  background: var(--color-shadow);
  border-radius: var(--border-radius-small);
  box-shadow: var(--shadow-popover);
  line-height: 1;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
}

.tooltip > * {
  color: inherit;
}

.tooltip[data-state='delayed-open'][data-side='top'] {
  animation-name: slideDownAndFade;
}

.tooltip[data-state='delayed-open'][data-side='right'] {
  animation-name: slideLeftAndFade;
}

.tooltip[data-state='delayed-open'][data-side='bottom'] {
  animation-name: slideUpAndFade;
}

.tooltip[data-state='delayed-open'][data-side='left'] {
  animation-name: slideRightAndFade;
}

.arrow {
  fill: var(--color-shadow);
}

.refOnly {
  all: unset;
  position: relative;
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}
