.container {
  padding: 6px 12px;
  border-radius: var(--border-radius-medium);
  background-color: var(--color-background-tertiary);
  max-height: 50vh;
  overflow: auto;
}

/* Two Column layout */
.container {
  display: flex;
  flex-grow: 1;
  width: 100%;
  flex-direction: row;
  column-gap: 12px;
  flex-direction: row;
  flex-wrap: wrap;
}

.row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  align-content: stretch;
  width: calc(50% - 6px);
  flex-wrap: nowrap;
}

.colProp {
  position: relative;
  flex-basis: 140px;
  color: var(--color-text-secondary);
}

/* Hide edit icon until hover */
.row .editIcon {
  opacity: 0;
  position: absolute;
  right: 0;
  transform: translateX(100%);
  transition: 0.3s var(--snappy-bezier);
}

.row:hover .editIcon {
  opacity: 1;
  transform: translateX(0);
}

.row:hover .private {
  opacity: 0;
}

.colVal {
  flex-basis: auto;
  flex-grow: 1;
  color: var(--color-text-heading);
  min-width: 0;
  border: none;
}

.value {
  width: 100%;
}

/* One Column Layout */
.container.oneColumn {
  flex-direction: column;
}

.container.oneColumn .row {
  width: 100%;
}

.container.oneColumn .colProp {
  flex-basis: 200px;
}