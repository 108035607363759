.button {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  font: var(--font-text);
  border: var(--border-invisible);
  border-radius: var(--border-radius-medium);
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  user-select: none;
  transition: background 0.1s ease, border 0.1s ease;
}

.button.disabled {
  pointer-events: none;
  opacity: 0.7;
}

.button:focus {
  outline: 1px solid #fff;
  outline-offset: -4px;
}

.button.container {
  width: 100%;
}

.label.label {
  text-decoration: inherit;
  max-width: 100%;
  color: inherit;
  font: inherit;
  align-items: center;
}

.loading {
  position: relative;
  pointer-events: none;
}

.loading .label {
  opacity: 0;
}

.loading .spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/*
* Sizes
*/

.tiny {
  padding: 0px 4px;
}
.tiny.iconLeft {
  padding-left: 2px;
}
.tiny.iconOnly.iconLeft,
.tiny.noText.iconLeft,
.tiny.iconRight {
  padding-right: 2px;
}

.small {
  padding: 5px 6px;
}
.small.iconLeft {
  padding-left: 4px;
}
.small.iconOnly.iconLeft,
.small.noText.iconLeft,
.small.iconRight {
  padding-right: 4px;
}

.regular {
  padding: 8px;
}
.regular.iconLeft {
  padding-left: 6px;
}
.regular.iconOnly.iconLeft,
.regular.noText.iconLeft,
.regular.iconRight {
  padding-right: 6px;
}

/*
* Variants
*/

.primary {
  background: var(--color-primary);
  border-color: var(--color-primary);
  color: var(--color-text-inverse);
}

.primary:hover,
.primary:global(.hover) {
  background: var(--color-primary-hover);
}

.primary:active,
.primary:global(.active) {
  background: var(--color-primary-active);
}

.primary .spinner,
.primary .icon {
  color: var(--color-icon-inverse);
}

.primaryAlt {
  background: var(--color-primary-alt);
  border-color: var(--color-border-primary-alt);
  color: var(--color-text-inverse);
}

.primaryAlt:hover,
.primaryAlt:global(.hover) {
  background: var(--color-primary-alt-hover);
}

.primaryAlt:active,
.primaryAlt:global(.active) {
  background: var(--color-primary-alt-active);
}

.primaryAlt .icon {
  color: var(--color-icon-inverse);
}

.secondary,
.subtleSecondary {
  background: transparent;
  border-color: var(--color-border);
  color: var(--color-text);
}

.secondary:hover,
.secondary:global(.hover) {
  background: var(--color-subtle-hover);
}

.secondary:active,
.secondary:global(.active) {
  background: var(--color-subtle-active);
}

.secondary .icon {
  color: var(--color-icon);
}

.danger,
.subtleDanger {
  color: var(--color-text-inverse);
  background: var(--color-danger);
}

.danger:hover,
.danger:global(.hover) {
  background: var(--color-danger-hover);
}

.danger:active,
.danger:global(.active) {
  background: var(--color-danger-active);
}

.danger .icon {
  color: var(--color-icon-inverse);
}

.ai,
.subtleAi {
  color: var(--color-text-inverse);
  background: var(--color-ai);
}

.ai:hover,
.ai:global(.hover) {
  background: var(--color-ai-hover);
}

.ai:active,
.ai:global(.active) {
  background: var(--color-ai-active);
}

.ai .icon {
  color: var(--color-icon-inverse);
}

.iconOnly {
  color: var(--color-icon);
  border-radius: 2px;
  margin: 0;
}

.iconOnly:hover,
.iconOnly:global(.hover) {
  background: var(--color-subtle-hover);
}

.iconOnly:active,
.iconOnly:global(.active) {
  background: var(--color-subtle-active);
}

.link {
  text-decoration: none;
  color: var(--color-text);
  font: inherit;
}

.link > span,
.link > p,
.link .label p {
  color: inherit;
}

.link:hover,
.link:global(.hover) {
  color: var(--color-primary);
}

.link:active,
.link:global(.active) {
  color: var(--color-text-heading);
}

/*
* Subtle Variants
*/

.subtle {
  border: var(--border-invisible);
}

.subtleSecondary {
  background: transparent;
  color: var(--color-text);
}

.subtleSecondary:hover,
.subtleSecondary:global(.hover) {
  background: var(--color-subtle-hover);
}

.subtleSecondary:active,
.subtleSecondary:global(.active) {
  background: var(--color-subtle-active);
}

.subtlePrimary {
  background: var(--color-subtle-primary);
  color: var(--color-text-primary);
}

.subtlePrimary:hover,
.subtlePrimary:global(.hover) {
  background: var(--color-subtle-primary-hover);
}

.subtlePrimary:active,
.subtlePrimary:global(.active) {
  background: var(--color-subtle-primary-active);
}

.subtlePrimary .icon {
  color: var(--color-text-primary);
}

.subtlePrimaryAlt {
  background: var(--color-subtle-primary-alt);
}

.subtlePrimaryAlt:hover,
.subtlePrimaryAlt:global(.hover) {
  background: var(--color-subtle-primary-alt-hover);
}

.subtlePrimaryAlt:active,
.subtlePrimaryAlt:global(.active) {
  background: var(--color-subtle-primary-alt-active);
}

.subtleDanger .icon {
  color: var(--color-text-danger);
}

.subtleDanger {
  background: var(--color-subtle-danger);
  color: var(--color-text-danger);
}

.subtleDanger:hover,
.subtleDanger:global(.hover) {
  background: var(--color-subtle-danger-hover);
}

.subtleDanger:active,
.subtleDanger:global(.active) {
  background: var(--color-subtle-danger-active);
}
