/* Resource Item Styles */

.note {
  display: block;
  position: relative;
  background: var(--color-background);
  border-radius: var(--border-radius-medium);
  transition: box-shadow 200ms ease;
  box-shadow: var(--shadow-elevation-low);
  border: var(--border-secondary);
  padding: 10px 12px;
  width: 100%;
  flex: 0 0 auto;
}

.note:hover {
  box-shadow: var(--shadow-elevation-mid);
}

.note.seen:not(.selected) .updateBody,
.note.seen:not(.selected) .person {
  color: var(--color-text-secondary);
}

.note.selected {
  background: var(--color-subtle-primary);
}

.richWrapper {
  line-height: 23px;
}

.updateBody {
  font: var(--font-text-medium);
  letter-spacing: -0.2px;
  padding: 0;
  word-break: break-word;
}

.bounded {
  /* For safari */
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 7em;
  /* For webkit supported browsers */
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  display: -webkit-box;
}

.unreadIndicator {
  position: absolute;
  top: 10px;
  left: unset;
  right: 10px;
}

.pin {
  opacity: 0;
  transition: all 200ms ease;
  max-width: 0;
}

.pin.pinned {
  opacity: 1;
  max-width: 44px;
}

.pin.pinned .icon {
  color: var(--color-text);
}

.note:hover .pin {
  opacity: 1;
  max-width: 44px;
}

.relation.relation {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  font: var(--font-text);
  color: var(--color-text-secondary);
  text-wrap: nowrap;
}

.threadReplies {
  border-radius: 4px;
  padding: 4px;
}