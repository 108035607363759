
.stroke {
  --stroke-direction: bottom right;
  --border-size: 1px;
  --border-offset: 1px;
  position: absolute;
  pointer-events: none;
  inset: 0;
  background: linear-gradient(to var(--stroke-direction),
      var(--color-user-purple),
      rgb(25, 28, 35, 0.2),
      rgb(25, 28, 35, 0.2)) no-repeat border-box,
    linear-gradient(to var(--stroke-direction),var(--color-user-purple), var(--color-border-secondary), var(--color-border-secondary)) no-repeat border-box;
  background-blend-mode: color-dodge;
  border-radius: inherit;
  border: calc(var(--border-size)) solid transparent;
  mask: linear-gradient(#000, #000) border-box,
    linear-gradient(#000, #000) padding-box;
  mask-composite: subtract;
}