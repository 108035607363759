.item {
  position: relative;
  list-style: none;
  width: 100%;
}

.item {
  padding: 7px 6px;
  border-radius: 6px;
  min-width: 0;
  transition: opacity 100ms ease;
  overflow: hidden;
  text-overflow: ellipsis;
}

.clickable {
  transition: box-shadow 50ms ease-in-out;
  border: var(--border-secondary);
  border-radius: var(--border-radius-medium);
  box-shadow: var(--shadow-elevation-low);
  background-color: var(--color-background);
}

.clickable:hover {
  box-shadow: var(--shadow-elevation-mid);
}

.clickable:active {
  box-shadow: var(--shadow-elevation-none);
  background-color: var(--color-background-quartenary);
}

.selectable.hover,
.selectable:hover {
  background: var(--color-subtle-primary-hover);
}

.selectable.selected,
.selectable:active {
  background: var(--color-subtle-primary-active);
}