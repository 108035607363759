
.heading.heading {
  color: var(--color-text-heading);
  text-align: left;
  /* min-width: 200px; */
  word-wrap: break-word;
  white-space: normal;
  display: inline-block;
  text-overflow: initial;
}

/* 2x strength */
.heading.h1 {
  font: var(--font-heading-large-bold);
  color: var(--color-text-heading);
}

.heading.h2 {
  font: var(--font-heading-bold);
  color: var(--color-text-heading);
}

.heading.h3 {
  font: var(--font-text-x-large-bold);
  color: var(--color-text-heading);
}
