.box {
  width: auto;
  flex-grow: 1;
  position: relative;
  color: var(--color-text);
  background: var(--color-background-tertiary);
  border: var(--border-invisible);
  border-radius: 7px;
}

.box > * {
  padding: 5px 8px;
}

.box:hover {
  background: var(--color-subtle-hover);
}

.box:active {
  background: var(--color-subtle-active);
}

.box:focus {
  background: var(--color-background);
  border-color: var(--color-primary);
}

.richText.twoLine {
  padding: 12px 8px 4px;
}

.twoLine > * {
  min-height: 4em;
}

.richText {
  padding: 5px 8px;
  white-space: pre-wrap;
  word-break: break-word;
}

.richText ol,
.richText ul {
  display: block;
  list-style-type: initial;
  padding: initial;
  margin-block-start: 0.5em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 20px;
}

.richText ol {
  list-style-type: decimal;
}

.richText pre {
  height: 100%;
  margin: 0;
}

.suggestions:empty {
  display: none;
}

.suggestions {
  display: block;
  background: var(--color-background);
  box-shadow: var(--shadow-elevation-mid);
  border: var(--border);
  border-radius: 4px;
  padding: 2px;
  width: 100%;
  position: absolute;
  bottom: 100%;
  left: 0;
}

.suggestion.active {
  background: var(--color-subtle-hover);
}

.suggestion.active {
  background: var(--color-subtle-active);
}

.userMention {
  display: inline-block;
  font: var(--font-text);
  color: var(--color-user-yellow);
  background: var(--color-user-yellow-3);
  padding: 0 6px;
  border-radius: 2px;
}

.richText .placeholder::before,
.plainText .placeholder::before {
  font: inherit;
  color: var(--color-text-placeholder);
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

/* readonly placeholder */
.subtle.subtle {
  color: var(--color-text-placeholder);
}