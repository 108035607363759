/* Status Icons */
.statusIcon {
  position: relative;
  /* width: 22px;
  height: 22px; */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.1s ease-out;
  flex-shrink: 0;
}


.disabled {
  pointer-events: none;
}

/* Hover states */

.blocked {
  position: absolute;
  top: -1px;
  right: -1px;
  width: 11px;
  height: 11px;
  min-width: 11px;
  min-height: 11px;
  line-height: 11px;
  font-size: 11px;
}

/* Colors */
.red {
  color: var(--color-user-red-2);
}

.blue {
  color: var(--color-user-blue-2);
}

.purple {
  color: var(--color-user-purple-2);
}

.brown {
  color: var(--color-user-brown-2);
}

.pink {
  color: var(--color-user-pink-2);
}

.yellow {
  color: var(--color-user-yellow-2);
}

.gray {
  color: var(--color-user-gray-2);
}

.orange {
  color: var(--color-user-orange-2);
}

.green {
  color: var(--color-user-green-2);
}